<template>
  <el-cascader
    style="width:100%;"
    v-model="areaIds"
    :size="size"
    :placeholder="placeholder"
    :options="addressOptions"
    :collapse-tags="isCollapseAge"
    :disabled="disabled"
    :props="props"
    clearable
    @change="handleChange"
  >
    <template #prefix>
      <i class="iconfont icon-login_verify"></i>
    </template>
  </el-cascader>
</template>

<script>
import { getAddressList } from "@/utils/splicingData";
export default {
  name: "selectAreaMul",
  data() {
    return {
      props: { multiple: true, checkStrictly: true },
      addressOptions: [],
      areaIds: [],
    };
  },
  props: {
    size: {
      type: [String],
      default: "medium",
    },
    placeholder: {
      type: [String],
      default: "请选择所在区域",
    },
    isCollapseAge:{
      type: [Boolean],
      default: true,
    },
    disabled:{
      type: [Boolean],
      default: false,
    },
    areaData: {
      type: [Array],
      default: () => {
        return [];
      },
    },
  },
  watch: {
    areaData(val) {
      this.areaIds = val;
      // console.log("areaIds", val);
    },
  },
  created() {
    //获取地址列表
    this.addressOptions = getAddressList();
  },
  // mounted() {
  //   this.areaIds = this.areaData;
  // },
  methods: {
    handleChange(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<style lang="less" scoped></style>
