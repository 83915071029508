<template>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
    <div v-if="pageType == 'auditList'">
      <div>您确定同意【{{ titleName }}】成为您的往来单位吗？</div>
      <div v-if="erpBinded">
        <div class="text-black-100 font-size-12 m-t-8">
          *
          请检查当前往来单位关联的本地往来单位是否正确，若有误请及时调整；若当前往来单位未选择本地往来单位则代表该往来单位还不是您的本地往来单位，直接审核通过后系统将自动在ERP中新建该往来单位！
        </div>
        <div class="flex items-center m-t-8">
          <div class="label">选择本地往来单位</div>
          <el-select
            class="flex-1"
            v-model="swCooperatorId"
            size="small"
            filterable
            remote
            reserve-keyword
            placeholder="本地往来单位"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="swCooperatorChange"
          >
            <el-option
              v-for="item in swCooperatorOptions"
              :key="item.swCustomerId"
              :label="item.swCustomerName"
              :value="item.swCustomerId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="flex items-center m-t-8">
        <div class="label">单位类型</div>
        <el-select
          class="flex-1"
          v-model="unitType"
          size="small"
          clearable
          multiple
          :disabled="unitTypeDisabled"
          placeholder="单位类型"
        >
          <el-option
            v-for="item in unitTypeOptions"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div v-if="pageType == 'unitList'">
      <div class="flex items-center m-t-8" v-if="erpBinded">
        <div class="label">选择本地往来单位</div>
        <el-select
          class="flex-1"
          v-model="swCooperatorId"
          size="small"
          filterable
          remote
          placeholder="本地往来单位"
          :remote-method="remoteMethod"
          :loading="loading"
          @change="swCooperatorChange"
        >
          <el-option
            v-for="item in swCooperatorOptions"
            :key="item.swCustomerId"
            :label="item.swCustomerName"
            :value="item.swCustomerId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex items-center m-t-8">
        <div class="label">单位类型</div>
        <el-select
          class="flex-1"
          v-model="unitType"
          size="small"
          clearable
          multiple
          :disabled="unitTypeDisabled"
          placeholder="单位类型"
        >
          <el-option
            v-for="item in unitTypeOptions"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div v-if="pageType == 'myFans'">
      <div>您确定要将【{{ titleName }}】设为您的往来单位吗？</div>

      <div v-if="erpBinded">
        <div class="text-black-100 font-size-12 m-t-8">
          *
          请在下面该选择该用户对应的本地往来单位名称；若当前用户未选择本地往来单位则审核通过后系统将自动在ERP中新建该往来单位且自动绑定！
        </div>
        <div class="flex items-center m-t-8">
          <div class="label">选择本地往来单位</div>
          <el-select
            class="flex-1"
            v-model="swCooperatorId"
            size="small"
            filterable
            remote
            reserve-keyword
            placeholder="本地往来单位"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="swCooperatorChange"
          >
            <el-option
              v-for="item in swCooperatorOptions"
              :key="item.swCustomerId"
              :label="item.swCustomerName"
              :value="item.swCustomerId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="flex items-center m-t-8">
        <div class="label">单位类型</div>
        <el-select
          class="flex-1"
          v-model="unitType"
          size="small"
          clearable
          multiple
          :disabled="unitTypeDisabled"
          placeholder="单位类型"
        >
          <el-option
            v-for="item in unitTypeOptions"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureFn">
        {{ pageType == "unitList" ? "保 存" : "确 定" }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getCooperatorErpList,
  postCooperatorAudit,
  postCooperatorBind,
  postCooperatorUserbind,
} from "@/api/cooperator";
import { mapState } from "vuex";

export default {
  props: {
    rowData: Object,
    dialogTitle: String,
    pageType: String,
  },
  data() {
    return {
      dialogVisible: false,
      swCooperatorId: null,
      swCooperatorOptions: [
        // {
        //   swCustomerId: 1, //# 往来单位ID
        //   swCustomerName: "往来单位名称1", // # 往来单位名称
        //   related: false,
        //   province: "", //# 省
        //   city: "", //# 市
        //   area: "", //# 区县
        //   contactName: "", // # 联系人
        //   contactPhone: "", // # 联系人电话
        //   isCustomer: true, // # 是否是客户
        //   isSupplier: false, //# 是否是供应商
        //   isLogistics: false, //# 是否是物流公司
        //   isInsuranceCompany: false, // # 是否是保险公司
        // },
        // {
        //   swCustomerId: 2, //# 往来单位ID
        //   swCustomerName: "往来单位名称2", // # 往来单位名称
        //   isCustomer: true, // # 是否是客户
        //   isSupplier: true, //# 是否是供应商
        // },
      ], //erp
      unitType: ["customer"],
      unitTypeOptions: [
        { name: "客户", code: "customer" },
        { name: "供应商", code: "supplier" },
        // { name: "客户+供应商", code: "customer-supplier" },
      ],
      unitTypeDisabled: false,
      loading: false,
      resFlag: false,
      count: 0,
    };
  },
  computed: {
    ...mapState({
      erpBinded: (state) => state.profile?.erpBinded,
    }),
    titleName() {
      let str = "";
      if (this.pageType == "auditList") {
        if (this.rowData.cooperatorType === 0) {
          str += this.rowData.creatorName;
          // + (this.rowData.swCooperatorName
          //   ? `（${this.rowData.swCooperatorName}）`
          //   : "");
        } else {
          str +=
            this.rowData.cooperatorName +
            (this.rowData.creatorName ? `-${this.rowData.creatorName}` : "");
          // +(this.rowData.swCooperatorName
          //   ? `（${this.rowData.swCooperatorName}）`
          //   : "");
        }
      } else if (this.pageType == "myFans") {
        str = this.rowData.companyId
          ? `${this.rowData.companyName}-${this.rowData.userName}`
          : this.rowData.userName;
      }
      return str;
    },
  },
  watch: {
    dialogVisible(val) {
      if (val && this.erpBinded) {
        this.getCooperatorErpListFn();
      }
    },
  },
  methods: {
    remoteMethod(query) {
      if (this.erpBinded) {
        this.loading = true;
        this.getCooperatorErpListFn(query);
      } else {
        this.swCooperatorOptions = [];
      }
    },
    getCooperatorErpListFn(name = "") {
      let params = {
        swCustomerName: name,
        isBind: 0, // # 是否绑定:1=已绑定的，-1=未绑定的，其他=全部
        page: 1, //# 页码
        pageSize: 200, //# 页大小
      };
      getCooperatorErpList(params).then((res) => {
        this.loading = false;
        this.swCooperatorOptions = res?.rows || [];
        if (this.pageType == "auditList" || this.pageType == "myFans") {
          let obj = {
            swCustomerId: 0,
            swCustomerName: "请选择",
          };
          this.swCooperatorOptions.unshift(obj);
        }
        if (this.count == 0) {
          this.swCooperatorId = this.rowData.swCooperatorId;
          this.swCooperatorChange(this.swCooperatorId);
          this.count++;
        }
      });
    },
    show() {
      this.resFlag = false;
      this.count = 0;
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    sureFn() {
      if (this.resFlag) return;
      this.resFlag = true;
      if (this.pageType == "auditList") {
        let text = "审核通过";
        let params = {
          id: this.rowData.id, //# 平台往来单位ID
          swCooperatorId: this.swCooperatorId, // # 本地往来单位客户id（传0为创建ERP往来单位）
          relationType: this.unitType, //# 往来单位类型，SwCooperatorId=0时有效 customer=客户 supplier=供应商 customer-supplier=客户+供应商
        };
        postCooperatorAudit(params).then(() => {
          this.$message.success(text);
          this.close();
          this.$emit("refresh");
        });
      }

      if (this.pageType == "unitList") {
        let text = "设置成功";
        let params = {
          id: this.rowData.id, //# 平台往来单位ID
          swCooperatorId: this.swCooperatorId, // # 本地往来单位客户id（传0为解绑）
          relationType: this.unitType,
        };
        postCooperatorBind(params).then(() => {
          this.$message.success(text);
          this.close();
          this.$emit("refresh");
        });
      }

      if (this.pageType == "myFans") {
        let params = {
          userId: this.rowData.userId, // # 平台用户ID
          swCooperatorId: this.swCooperatorId, //# 本地往来单位客户id（传0为创建）
          relationType: this.unitType,
        };
        postCooperatorUserbind(params).then(() => {
          this.$message.success("绑定成功");
          this.close();
          this.$emit("refresh");
        });
      }
    },
    swCooperatorChange(value) {
      if (value) {
        let selObj = null;
        this.swCooperatorOptions.forEach((p) => {
          if (p.swCustomerId == value) {
            selObj = p;
          }
        });
        let arrType = [];
        if (selObj?.isCustomer) {
          arrType.push("customer");
        }
        if (selObj?.isSupplier) {
          arrType.push("supplier");
        }
        this.unitType = arrType;
        this.unitTypeDisabled = true;
      } else {
        this.swCooperatorId = null;
        let arrType = [];
        if (this.rowData?.isCustomer) {
          arrType.push("customer");
        }
        if (this.rowData?.isSupplier) {
          arrType.push("supplier");
        }
        this.unitType = arrType;
        this.unitTypeDisabled = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.label {
  width: 140px;
}
</style>
